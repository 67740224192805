* {box-sizing: border-box;}
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, button, input, textarea, p, select, blockquote, th, td, img, a {margin: 0;padding: 0;font-weight: 300;color: #000;font-family: "Pretendard", "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;font-size: 1em;word-break: keep-all;word-wrap: break-word;}
html, body {font-size: 16px;width: 100%;height: 100% !important;overflow-x: hidden;overscroll-behavior: contain;}
body.active {overflow-y: hidden;}
li {list-style: none;}
a {color: #000;}
table {width: 100%;border-collapse: collapse;border-spacing: 0;}
img {border: 0;}
button {background: transparent;border: 0;cursor: pointer;outline: 0;}
input, select, textarea {border: 1px solid #aaa;-webkit-appearance: none;-webkit-border-radius: 0;outline: none;}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}

/*공통*/
.pointer{cursor: pointer;}

/*scroll*/
::-webkit-scrollbar {height: 6px;}
::-webkit-scrollbar-track {background: #FFF}
::-webkit-scrollbar-thumb {background: rgba(217, 217, 217, 0.50);border-radius: 10px;}
::-webkit-scrollbar-thumb:hover {background: rgba(217, 217, 217, 1);}

.black-text{background: #000 !important;}

/*menu*/
.menu-box{display: flex;margin-bottom: 20px;}
.menu-selected{font-size: 17px;line-height: 46px;border: 0;box-shadow: none;text-align: center;font-weight: 500;width: 310px; color: #000000; border-bottom: 4px solid #000;cursor: pointer;}
.menu-none-selected{font-size: 17px;line-height: 46px;border: 0;box-shadow: none;text-align: center;font-weight: 500;width: 310px; color:#C0C4CC; border-bottom: 4px solid #C0C4CC;cursor: pointer;}
.menu-none{width: 100%; border-bottom: 4px solid #C0C4CC}
.menu-badge{display: flex;width: 18px;height: 18px;font-size: 11px;margin-left: 5px;align-items: center;justify-Content: center;border-radius: 50%;background: #FF6533;color: #FFFFFF;}
.menu-box .menu-badge-box{display: flex;align-items: center;justify-content: center;}

/*search*/
.search-area{width: 100%;min-height: 50px; margin-top:15px; margin-bottom: 15px; white-space : nowrap;display: flex;justify-content: space-between;}
.search-input{min-width: 30%;height: 40px}
.search-select{width: 15%;margin-left: 10px}
.search-date{width: 35%;height: 40px;margin-left: 10px}

/*checkbox*/
.ant-checkbox-checked .ant-checkbox-inner{background-color: #FF6533;border-color: #FF6533;border-radius: 5px;}
.ant-switch-checked{background: #FF6533;}

/*popover*/
.ant-popconfirm {padding: 50px;width: 550px;}
.ant-popover-message-title {color: #181818; font-weight: 500;line-height: 15px;}

/*table*/
.table-gray-column{background-color: #F9FBFC !important;}
.ant-table-row{height: 50px;}
.salary-second-table.page-2 .ant-table-thead .ant-table-cell{height: 74px; padding-right: 5px}
.salary-second-table.page-3 .ant-table-thead .ant-table-cell{height: 74px;}

/*modal*/
.modal-title-box{color: #181818;font-size: 15px;line-height: 18px;}
.modal-title-box .main-title{font-weight: 500;}
.modal-title-box .sub-title{font-weight: 300;margin-top:5px;}

.modal-contents-box{display: flex;flex-direction: column;align-items: flex-start;gap: 14px;align-self: stretch;margin-top: 20px;}
.modal-contents-item{display: flex;align-items: center;gap: 10px;width: 100%;}
.modal-contents-item .label{color: #181818;font-size: 15px;font-weight: 500;line-height: 15px;width: 25%;}

.modal-title{font-weight: 700;font-size: 24px;text-align: center;}
.modal-sub-title{font-size: 14px;font-weight: 400;margin: 10px 0 10px 0;text-align: center;}

.ant-modal-body{padding: 24px !important;}

/*padding*/
.p-lr-20{padding: 0 20px 0 20px !important;}

/*salary*/
.salary-table-box{overflow: auto;width: 100%;height: 100%;display: flex;}
.salary-table {width: 100%;box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.10);z-index: 10;}
.salary-table .ant-table-thead {height: 75px;}
.salary-table .ant-table-tbody {height: 59px;}
.salary-second-table {width: 100%;background: #F9FBFC;}
.salary-second-table .ant-table-tbody > tr > td {background-color: #F9FBFC;}
.salary-second-table .ant-table-thead > tr > th, .salary-second-table .ant-table-thead > tr > th{background: #F9FBFC;}
.salary-second-table .ant-table-container table > thead > tr:first-child th{border-top-left-radius: 3px;background: #EEE;color: #959BA0;border-bottom: 0.5px dashed #C7C7C7;padding: 2px;}

.table-progress-box{display: flex;font-size: 23px;justify-content: space-around;width: fit-content;border: 2px solid #FF6533;padding: 7px;border-radius: 8px;height: 40px;align-items: center;gap: 10px;margin-left: 10px;}
.table-progress-item{font-size: 12px;font-weight: 500;color: #FF6533; opacity: 0.3;}
.table-progress-item.active{opacity: 1;}

/*tab*/
.tab-box{width: 100%;min-height: 20px;margin-bottom: 15px;white-space: nowrap;display: flex;justify-content: space-between; font-weight: 500}
.tab-box .label{font-weight: 500;font-size: 12px;color: #A1A1A1;display: flex;align-items: center;}
.tab-box .menu{font-size: 14px;line-height: 46px;border: 0;box-shadow: none;text-align: center;font-weight: 500;color:#959BA0;white-space: nowrap;margin-left: 5px;margin-right: 5px;cursor: pointer;}
.tab-box .menu.active{color: #FF6533;}

/*icon*/
.anticon-exclamation-circle{color: #FF6533;}

/*text*/
.font-small{font-size: 13px;line-height: 18px;}
.underline{text-decoration: underline;}

/*plan*/
.plan-box{border-radius: 8px;padding: 30px 8px 30px 8px;margin-left: 5px;margin-bottom: 8px;}
.plan-box div{text-align: center;color: #959BA0;}
.plan-box.plan{background: #09519A;}
.plan-box.plan div{color: #FFF;}
.plan-box .plan-title{font-size: 24px;font-weight: 700;}

/*공통*/
.max{width: 100% !important;}
.black-hr{border-bottom: 1px dashed #000;margin-top: 15px;margin-bottom: 15px;}
.gray-hr{border-bottom: 1px solid #CFCFCF;margin-top: 15px;margin-bottom: 15px;}
.primary-hr{border-bottom: 1px solid #FF6533;margin-top: 15px;margin-bottom: 15px;}
.hr-dashed {border-bottom: 1px dashed #CFCFCF;margin-top: 5px;margin-bottom: 5px;}
.hr-opacity{opacity: 0.1}
.flex{display: flex;justify-content: center;}
.align-center{display: flex;flex-direction: column;align-items: center;}
.align-end{display: flex;flex-direction: column;align-items: flex-end;}
.logo-image {width: 300px;}
.height-auto{height: auto !important;}

.horizontality-box{display: flex;gap: 10px;}
.horizontality{display: flex;flex-direction: column;width: 25%;}
.horizontality.col1{width: 100%;}
.horizontality.col2{width: 50%;}
.horizontality.col3{width: 33.3%;}
.horizontality.col4.small{width: 40%;}
.horizontality.col4.medium{width: 50%;}
.horizontality.col4.large{width: 60%;}

.verticality-box{display: flex;width: 100%;height: 25%;align-items: flex-start;flex-shrink: 0;}
.verticality{display: flex;width: 50%;padding: 16px;flex-direction: column;align-items: flex-start;gap: 16px;flex-shrink: 0;align-self: stretch;}
.verticality-item{display: flex;width: 100%;height: 100%;align-items: flex-start;flex-shrink: 0;}

/*toast*/
.ant-message-notice-content {background-color: #000000 !important;color: #ffffff !important;border-radius: 8px;}
.ant-message-success {color: #FFF;}
.ant-message-warning {color: #FFF;}
.ant-message-error {color: #FFF;}

/*list*/
.title {width: 100%;display: flex;align-items: center;justify-content: flex-start;font-size: 32px;font-weight: 600;line-height: 32px;margin-bottom: 20px}
.box{width: 100%;display: flex;flex-direction: column;position: relative;padding: 1.25rem;min-width: 0;word-wrap: break-word;background-color: #fff;background-clip: border-box;border-radius: 8px;min-height: 1px;height: 98%;margin-bottom: 20px;box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);overflow: auto;}

/*pagination*/
.ant-pagination-options {display: none;}

/*icon*/
.icon{width:20px; height:20px}
.tooltip-icon{width:20px; height:20px;margin-top:3px;}
.anticon-question-circle{color: #959BA0;}
.ant-menu-item-active .ant-menu-item-icon{filter: brightness(0%) saturate(100%);}

/*table*/
.table-box{overflow: auto; width: 100%; height: 100%;}
.ant-table{height: 100%;}
.ant-table-thead > tr > th {color: #959BA0;font-weight: 500;line-height: 12px;letter-spacing: -0.45px;background: white;}
.ant-table-tbody > tr > td, .ant-table-tbody > tr > td > .center {background-color: #ffffff;color: #333;font-weight: 500;font-size: 12px;line-height: 17px;letter-spacing: -0.45px;border-radius: 5px;padding: 7px 7px;}
.ant-table-tbody > tr:hover > td {background-color: #ffffff;}
.ant-table-tbody > tr {border: 0;}
.ant-table-placeholder > .ant-table-cell{color: #959BA0 !important;}
.ant-table-cell{text-align: center !important;}

.ant-table-cell-title{
    line-height: normal !important;
    color: #959BA0 !important;
    font-weight: 500 !important;

    & p {
        line-height: normal !important;
        color: #959BA0 !important;
        font-weight: 500 !important;
    }
}

/*select*/
.ant-select{max-width: 100%;}
.ant-select.col2{width: 45%;}
.ant-select-selector {position: relative !important;display: inline-block !important;width: 100% !important;height: 40px !important;min-width: 0 !important;line-height: 1.5715 !important;background: #F9FBFC !important;border-radius: 8px !important;transition: all 0.3s !important;padding: 4px 11px !important;vertical-align: middle;}
.ant-select-selection-item {margin-top: 15px;}
.ant-select-dropdown {background-color: #F9FBFC !important;border-radius: 8px;}
.ant-select-arrow{top: 43%;}
.ant-select-selector{border: 0 !important;}
.ant-select-label-large{width: 70%;}

/*input*/
.ant-input, .ant-picker{background: #F9FBFC;border: 0 !important;border-radius: 8px;transition: all 0.3s;}
.ant-input.max, .ant-picker.max{height:40px;}
.ant-input-affix-wrapper {background: #F9FBFC !important;border: 1px solid #FFF !important;border-radius: 8px !important;transition: all 0.3s !important;}
.ant-input-affix-wrapper > input.ant-input {background: #F9FBFC;}
.ant-input.col2{width: 45%;}
.ant-input.checkbox, .ant-input-affix-wrapper.checkbox, .ant-picker.checkbox{width: 50%;}
.ant-input-suffix{color: #909399;font-size: 12px;}
.ant-input.col-1{font-size: 14px;font-weight: 500;width: 20%;margin-right: 5px;}
.ant-input.col-1-middle{width: 8%;margin-left: 0;margin-right: 0;border-radius: 0;}
.ant-input.col-1-left{width: 15%;margin-right: 0;border-radius: 8px 0 0 8px;font-size: 14px;font-weight: 500;text-align: center;padding: 0 0 0 10px;}
.ant-input.col-1-right{width: 15%;margin-left: 0;border-radius: 0 8px 8px 0;font-size: 14px;font-weight: 500;text-align: center;padding: 0 10px 0 0;}
.ant-input.col-2{width: 35%;margin-right: 5px;}
.small-input-text{padding: 0;font-size: 11px;}


.salary-etc-cell{display: flex;gap: 5px;align-items: center;
    & svg {
        color: #959BA0;
    }
}
.salary-etc-input-text{padding: 0;font-size: 13px; font-weight: 500; min-width: 50px; color: #959BA0;}

/*button*/
.button{border-radius: 11px;height: 40px;font-size: 14px;font-weight: 400;margin-left: 5px;padding: 0 10px 0 10px;}
.small-button{border-radius: 2px;border: 2px solid;font-size: 12px;height: 30px;padding: 2px 8px;font-weight: 500;}
.button-area{display: flex; justify-content: flex-end; width: auto; text-align: right}
.gray-button{background: #959BA0;color: #FFF;}
.green-button{background: #41A56A;color: #FFF;}
.button > a {font-size: 14px;font-weight: 400;color:#FFF;padding: 10px 10px 0 10px;display: flex;}
.primary-button, .primary-button:hover, .primary-button:focus, .primary-button:active, .primary-button[disabled]{background: #FF6533;color: #EEF2F5;}
.ant-btn[disabled]{opacity: 0.3;}
.primary-soft-button{background: #FFC5B0;;color: #FFF;}
.primary-ghost-button, .primary-ghost-button:hover, .primary-ghost-button:focus, .primary-ghost-button:active, .primary-ghost-button[disabled]{background: #FFFFFF;color: #FF6533;border: 1px solid #FF6533;}
.cancel-button{background: #A1A1A1;color: #FFF;}
.delete-button{background: #FFEEEF;color: #D68A8B;}
.upload-button{background: #F9FBFC;color: #FF6533;}
.button-icon{margin-bottom: 2px; margin-left: 5px}
.checkbox-button{border-radius: 12px;background: #F9FBFC;color: #959BA0;border-color: #F9FBFC;margin-right: 5px;font-size: 14px;font-weight: 500;line-height: normal;padding: 12px 14px;}
.checkbox-button.active{background: #FF6533;color: #EEF2F5;}
.button.col2{width: 45%;margin-left: 5px;margin-right: 5px;}
.button.col2-full{width: 50%;}
.button.col3{width: 29%;margin-left: 5px;margin-right: 5px;}
.floating-button{display: flex; justify-content: flex-end; width: 100%;}
.plus-button{display: flex;padding: 8px;color: #FFF;margin-left: 5px;justify-content: center;align-items: center;border-radius: 8px;border: 1px solid #FFC5B0;background: #FF6533;}
.minus-button{display: flex;padding: 8px;color: #FF6533;margin-left: 5px;justify-content: center;align-items: center;border-radius: 8px;border: 1px solid #FF6533;}
.radio-button{border-radius: 40px;background: #F9FBFC;color: #141B34;border: 0;padding: 12px 0;width: 100%;height: auto;}
.radio-button.active{background: #141B34;color: #F9FBFC;}
.radio-primary-button{border-radius: 11px;background: #F9FBFC;color: #141B34;border: 0;padding: 12px 0;width: 100%;height: auto;}
.radio-primary-button.active{background: #FF6533;color: #F9FBFC;}

/*popover*/
.ant-popover-inner {display: flex;justify-content: center;align-items: center;text-align: center;color: #000000;background-color: white !important;border-radius: 8px !important;font-weight: 500;}
.ant-popover-inner-content {color: #000000;}
.ant-popover-message {display: flex;flex-direction: row;justify-content: center;align-items: center;}
.ant-popover-message > .anticon {position: relative;top: 0;color: #FF6533;}
.ant-popover-buttons {display: flex;justify-content: center;border-radius: 8px !important;}
.ant-popover-buttons .ant-btn {background: #A1A1A1;border-color: #A1A1A1;color: #FFFFFF !important;border-radius: 8px;height: 30px;}
.ant-popover-buttons .ant-btn.ant-btn-primary {background: #FF6533;border-color: #FF6533;}
.ant-popover-arrow {width: 0;height: 0;}
.popover-title{font-size:15px;font-weight: 500;}
.popover-input{font-weight: 500;font-size: 14px;background-color: #FFF;border: 0;border-bottom: 2px solid #FF6533;border-radius: 0;}

/*drawer*/
.ant-drawer-right > .ant-drawer-content-wrapper {min-width: 1000px !important;}
.ant-drawer-right.large > .ant-drawer-content-wrapper {min-width: 1350px !important;}
.ant-drawer-header-title {width: 300px !important;flex: none;}
.ant-drawer-title {width: 300px !important;flex: none;font-weight: 500;font-size: 30px;}
.ant-drawer-extra {flex: 1;}
.ant-descriptions-bordered .ant-descriptions-item-content {padding: 0;}
.drawer-box{border-bottom: 1px solid #F0F0F0;padding: 16px;gap: 10px;}
.drawer-box.col2{display: flex;justify-content: space-between;}
.drawer-box > .col2{width: 50%;padding: 20px;}
.drawer-item{margin-bottom: 15px;}
.drawer-checkbox-label{font-weight: 400;font-size: 12px;display: flex;color: #959BA0;}
.drawer-black-label{color: #141B34;margin: auto;}
.drawer-long{width: 740px;height: 40px;font-weight: 500}
.drawer-mid{width: 740px;height: 40px;font-weight: 500}
.drawer-require{border: 2px solid #FF6533 !important;}
.drawer-require-prefix{font-weight: 500; font-size: 12px; color: #FF6533}
.drawer-p-12{font-weight: 500; font-size: 12px;}
.drawer-border-top{display: flex;align-items: center;margin-top: 15px;border-top: 2px solid #EAEAEC;padding-top: 20px;}
.drawer-descriptions-item{width: 120px}

/*checkbox*/
.ant-checkbox{border-radius: 8px; margin-right: 5px;}

/*plan*/
.plan-modal{width: 50% !important;}
.plan-footer{color: #5A7184;font-size: 16px;font-weight: 400;line-height: 36px;}
.plan-title{display: flex;justify-content: center;font-weight: 500;font-size: 16px;align-items: center;margin-bottom: 15px;}
.plan-modal-box{width: 100%;height: 100%;display: flex;flex-direction: row;justify-content: space-between;}
.plan-box-item{width: 48%;height: 100%;display: flex;background: #F9FBFC;border-radius: 8px;padding: 36px;flex-direction: column;}
.basic-plan-box{border-top: 6px solid #09519A;}
.premium-plan-box{border-top: 6px solid #1795D3;}
.plan-title-box{padding-bottom: 20px;border-bottom: 1px solid #E5EAF4;}
.plan-title-area{display: flex;width: 290px;justify-content: space-between;align-items: flex-end;}
.plan-sub-text{color: #5A7184;font-size: 16px;font-weight: 400;}
.plan-text-large{line-height: normal;font-size: 40px;font-weight: 700;margin-right: 5px;}
.plan-text-sub{line-height: normal;font-size: 24px;font-weight: 700;margin-right: 5px;}
.plan-primary-premium-color{color: #1795D3;}
.plan-primary-basic-color{color: #09519A;}
.plan-contents-box{display: flex;flex-direction: column;margin-top: 15px;height: 420px;}
.plan-contents-item{display: flex;align-items: center;margin-bottom: 20px;}
.plan-contents-item-contents{color: #183B56;font-size: 16px;font-weight: 400;margin-left: 10px;}
.plan-button{width: 100%;height: 50px;display: flex;padding: 12px 24px;justify-content: center;align-items: center;gap: 10px;align-self: stretch;border-radius: 8px;background: #FFF;font-size: 18px;font-style: normal;font-weight: 700;line-height: normal;}
.plan-button.basic {border: 2px solid #09519A;color: #09519A;}
.plan-button.premium {border: 2px solid #1795D3;color: #1795D3;}
.plan-disabled-button {width: 100%;height: 50px;display: flex;padding: 12px 24px;justify-content: center;align-items: center;gap: 10px;align-self: stretch;border-radius: 8px;border: 2px solid #1795D3;cursor: not-allowed;background: #F9FBFC;opacity: 0.3;color: #09519A;font-size: 18px;font-style: normal;font-weight: 700;line-height: normal;}
.plan-function-box{display: flex;background: #E1F4EC;height: 100px;margin: 5px 2px 15px 2px;border-radius: 12px;padding: 30px;align-items: center;justify-content: center;}
.plan-button-box {display: flex;align-items: center;justify-content: center;margin-top: auto;}













/*switch*/
.ant-switch.right.large{margin-left: 40%;}

/*margin*/
.m-0{margin: 0 !important;}
.ml-5{margin-left: 5px;}
.ml-10{margin-left: 10px;}
.mt-5{margin-top: 5px;}
.mt-10{margin-top: 10px;}
.mt-20{margin-top: 20px;}
.mr-10{margin-right: 10px;}
.mb-5{margin-bottom: 5px;}
.mb-10{margin-bottom: 10px;}
.mb-17{margin-bottom: 17px;}
.mb-20{margin-bottom: 20px;}
.right{margin-left: auto;}
.pr-10{padding-right: 10px;}

.nowrap{white-space: nowrap !important;}

.opacity {opacity: 0.3}

/*tag*/
.ant-tag {background: rgba(255, 101, 51, 0.30);color: #FF6533;border-radius: 4px;font-size: 11px;font-weight: 700;text-align: center;align-content: center;width: 145px;height: 25px;}

.file-tag-box{display: flex;gap: 10px;width: 100%;flex-wrap: wrap;}
.file-tag{display: flex;padding: 12px;align-items: center;gap: 8px;justify-content: center;flex-shrink: 0;border-radius: 8px;background: #F9FBFC;color: #181818;font-size: 14px;font-weight: 500;line-height: 10px;}

/*pagination*/
.pagination{width: 100%;margin:30px 0 0 auto;display: flex;align-items: center;justify-content: flex-end;}

/*text*/
.danger{color: #FF6533;}
.primary{color: #ff6533;}
.center{text-align: center;}
.cancel{color: #A1A1A1}
.text-right{text-align: right;}
.table-sub-text{color: #959BA0;text-align: center;font-size: 11px;font-weight: 500;}
.table-sub-back-text{color: #000000;text-align: center;font-size: 11px;font-weight: 500;}
.soft-text{color: #959BA0;}
.primary-text{font-size: 20px;font-weight: 400;color: #ff6533;margin-bottom: 9px;}
.primary-text.small{font-size: 10px;font-weight: 500;margin: 0;}
.primary-text.medium{font-size: 12px;font-weight: 500;margin: 0;}
.gray-text{color: #959BA0;}
.green-text{color: #36B37E;}
.required{color: #FF6533;font-size: 13px;font-weight: 500;line-height: 15px;margin-right: 3px;}
.description{margin-left:25px;}
.description, .description > a{color: #141B34;font-weight: 400;opacity: 0.6;}
.bold{font-weight: 500;}

.text-black{color: #000 !important;}
.text-soft-brown{color: #E2B086 !important;}

/*form*/
.setting-box{width: 100%;height: 100%;display: flex;flex-direction: column;position: relative;padding: 16px;word-wrap: break-word;background-color: #FFF;background-clip: border-box;border-radius: 8px;margin-bottom: 10px;box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);}
.setting-box.padding{padding-top: 56px;padding-bottom: 56px;}
.setting-box.fit{height: fit-content;}
.setting-box.col3{display: flex;flex-direction: column;}
.setting-box.second{margin-top: 5px;}
.setting-box.max{width:100% !important;margin-right: 0;height: fit-content !important;}
.setting-box.small{width: 40% !important;}
.setting-box-label{display: flex;align-items: center;font-size: 18px;font-weight: 500;margin-bottom: 20px;}
.setting-box-label.small{font-size: 13px;margin-bottom: 5px;width: 100%;}
.setting-box-contents{font-weight: 400;font-size: 12px;display: flex;color: #959BA0;}
.setting-item-title{font-weight: 500;font-size: 16px;}
.setting-item-contents{font-weight: 400;font-size: 13px;color: #959BA0;line-height: 18px;letter-spacing: -0.36px;}
.setting-item-box{display: flex;justify-content: space-between;align-items: center;width: 100%;padding: 19px;border-radius: 8px;border: 1px solid #F4F6F6;background: #F9FBFC;margin-bottom: 10px;cursor: pointer;}
.setting-item-box.select-box{border: 2px solid #FF6533;}
.disabled-color{background-color: #F9FBFC !important;}

.gray-box{background-color: #F9FBFC;padding: 20px;}
.gray-box.small{padding: 10px;}
.gray-box-item{display: flex;margin-bottom: 10px;}
.gray-box-label{color: #959BA0;font-size: 14px;font-weight: 400;width: 70%;margin: auto;}
.gray-box-input{width: 30%;background: #FFF !important;}

/*modal*/
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {color: #FFFFFF;background: #FF6533;}
.ant-modal-content {border-radius: 18px;}
.ant-modal-footer {border-top: 0;margin-top: auto;text-align: center;}
.ant-modal-title {font-weight: 500;font-size: 20px;}
.ant-modal-header {border-bottom: 0;border-radius: 12px;padding: 30px;}

.dropdown-item{display: flex;width: 100%;padding: 10px 6px;align-items: flex-start;justify-content:space-between;border-bottom: 0.5px solid #EAEAEC;gap: 10px;color: #141B34;font-size: 14px;font-weight: 500;line-height: 12px;}
.dropdown-item-box{display: none;}
.dropdown-item-box.active{display: flex;}
.dropdown-item-sub{display: inline-flex;justify-content: space-between;align-items: center;width: 100%;}
.dropdown-item-sub-text{padding: 10px 14px;color: #141B34;font-size: 14px;font-weight: 500;line-height: 12px;}

.employee-box{display: flex;padding: 10px;margin-top: 10px;justify-content: center;background: #F9FBFC;height: 50px;align-items: center;border-radius: 12px;border: 0;color: #181818;font-size: 13px;font-weight: 400;}
.employee-box.active{border: 1px solid #FF6533;}
.employee-box-inner-text{color: #959BA0;font-size: 12px;font-weight: 500;line-height: 10px;}

/*login*/
.login-container{width: 100%;display: flex;justify-content: center;align-items: center;background-color: #F9FBFC;}
.login-container.full{width: 100%;height: 100vh;}
.login-box{display: flex;padding: 60px 40px;flex-direction: column;justify-content: center;align-items: center;gap: 40px;border-radius: 8px;background: #FFF;box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);width: 45%;max-width: 700px;min-width: 600px;}
.login-box .subTitle{color: #0C2B48;font-size: 24px;font-weight: 500;line-height: normal;letter-spacing: -1.08px;}
.login-box .subTitleContents{color: #0C2B48;text-align: center;font-size: 17px;font-weight: 500;line-height: 140%; /* 23.8px */letter-spacing: -0.765px;}
.login-box .input-box{display: flex;flex-direction: column;gap: 16px;width: 100%;}
.login-box .input-box .select-item{display: flex;gap: 32px;align-self: stretch;border-radius: 8px;border: 1px solid #F4F6F6;background: #F9FBFC;padding: 16px;color: #0C2B48;font-size: 15px;font-weight: 600;line-height: 15px;letter-spacing: -0.675px;cursor: pointer;}
.login-box .input-box .contents{color: #959BA0;text-align: center;font-size: 20px;font-weight: 400;line-height: 160%;}
.login-box .input-box a{color: #0C2B48;font-size: 14px;font-weight: 500;line-height: 15px;letter-spacing: -0.63px;text-decoration: underline;text-align: right;}
.login-box .input-box .input-item{display: flex;flex-direction: column;align-items: flex-start;gap: 8px;}
.login-box .input-box .input-item.col2{width: 50%;}
.login-box .input-box .label{color: #0C2B48;font-size: 14px;font-weight: 500;line-height: 15px;letter-spacing: -0.63px;}
.login-box .input-box input{display: flex;width: 100%;height: 52px;padding: 8px 16px;align-items: center;gap: 16px;}
.login-box .input-box .ant-select{width: 100%;}
.login-box .input-box .ant-select-selector,.ant-select-selection-search-input{padding: 12px 16px !important;width: 100% !important;height: 54px !important;}
.login-box .input-item-box.col2{display: flex;gap: 10px;}
.login-button{padding: 8px;height: 56px;margin-top: 40px;}

.input-button-box{display: flex;align-items: center;gap: 4px;justify-content: center;}
.input-button-box .ant-input.sub{margin-left: 20px;}
.button-icon{width: 20px;height: 20px;}
.button-icon-width18{width: 18px;height: 20px;}

.badge-ghost{display: flex;width: fit-content;padding: 5px 4px;align-items: center;border-radius: 5px;font-weight: 500;line-height: 10px;letter-spacing: -0.36px;height: 28px;}
.badge{display: flex;width: fit-content;padding: 5px 4px;align-items: center;border-radius: 5px;font-weight: 500;line-height: 10px;letter-spacing: -0.36px;height: 28px;font-size: 12px;}
.badge-primary{border: 2px solid #FF6533;background: #FF6533;color: #FFF;}
.badge-primary-ghost{border: 2px solid #FF6533;color: #FF6533;}
.badge-green-ghost{border: 2px solid #71A185;color: #71A185;}
.badge-gray-ghost{border: 2px solid #959BA0;color: #959BA0;}
.badge-gray{background: #E8EBF2;color: #959BA0;}
.badge-green{background: #E8F2ED;color: #71A185;}
.badge-danger{background: #FFEEEF;color: #D68A8B;}
.badge-yellow{background: #FFF4EF;color: #E2B086;}
.badge-small{font-size: 10px;gap: 5px;padding: 2px 4px;font-weight: 500;height: fit-content;}
.badge-box{margin-left: 10px;}

.grid{display: grid;gap: 5px;}
.flex{display: flex;gap: 5px;align-items: center;}
.flex-end{display: flex;gap: 5px;align-items: flex-end;}
.flex-start{justify-content: flex-start !important;align-items: center;}
.flex-space{display: flex;width: 100%;justify-content: space-between !important;align-items: center;}
.text-right{text-align: right !important; justify-content: flex-end !important;}

.width-none{width: revert !important;}
.width-100{width: 100% !important;}
.width-100px{width: 100px !important;}

.height-100{height: 100%}

.drawer-item .title{color: #141B34;font-size: 12px;font-weight: 500;line-height: 12px;letter-spacing: -0.54px;}
.drawer-item .sub-title{color: #959BA0;font-size: 12px;font-weight: 500;line-height: 12px;letter-spacing: -0.54px;}
.drawer-item .label{color: #959BA0;font-size: 10px;font-weight: 500;line-height: 10px;letter-spacing: -0.45px;}

.year-box{border-radius: 8px;border: 0.5px solid #EAEAEC;background: #FFF;filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));margin-bottom: 9px;}
.year-item{display: flex;width: 100%;padding: 6px 12px;flex-direction: column;align-items: flex-start;gap: 10px;border-radius: 8px;}
.year{display: flex;gap: 23px;align-items: center;font-size: 14px;font-weight: 500;}

.dropdown-box{display: flex;align-items: center;justify-content: space-between;padding: 2px;}
.dropdown-box-item{display: flex;align-items: center;font-size: 15px;font-weight: 500;}
.dropdown-bot-item-contents{padding: 10px 10px 10px 20px;}

.workflow-box{display: flex; width: 100%; justify-content: space-between}
.workflow-title{font-weight: 500; font-size: 20px}

.todo-date-box{display: flex; justify-content: center; font-weight: 200}
.todo-date{display: flex;margin-top: 10px;margin-bottom: 10px;justify-content: center;align-items: center;gap: 6px;border-radius: 17px;background: #141B34;width: 200px;height: 35px;color: #FFFFFF;}

.todo-contents-box{display: flex; width: 100%;margin-bottom: 5px;}
.todo-contents{font-weight: 500; font-size: 13px;}
.todo-contents-title{display: flex; width: 40%;}
.todo-contents-text{display: flex; width: 60%;}

.todo-alarm-icon{height: 18px}
.todo-alarm-time{padding: 3px;background: #FF6533;color: #FFFFFF;border-radius: 8px;font-size: 12px;margin-left: 5px;margin-right: 5px}

.radius-button{align-items: center;border-radius: 50%;color: #fff;display: flex;font-size: 24px;height: 25px;justify-content: center;width: 25px;}
.radius-plus-button{background: #ff6533;}
.radius-minus-button{background: #ffc5b0;}

.ant-col {padding: 5px;}

.plan-card-box{display: flex;padding: 20px;justify-content: center;align-items: center;gap: 4px;flex: 1 0 0;align-self: stretch;color: #959BA0;margin-bottom: 20px;font-size: 14px;font-weight: 500;border-radius: 8px;border: 1px dashed #EAEAEC;}

.month-box{display: flex;width: 100%;height: 31px;padding: 9px 8px;flex-direction: column;align-items: flex-start;gap: 10px;flex-shrink: 0;background: #FFF;}
.month-item{display: flex;align-items: center;gap: 23px;color: #141B34;font-size: 16px;font-weight: 500;}

.ant-picker-input>input {font-size: 11px;}

.ant-spin-nested-loading{height: 100% !important;}
.ant-spin-container{height: 100% !important;}

/*수정필요*/
.workFlow-title-area{width: 100%;display: flex;justify-content: space-between;margin-bottom: 2px}
.workFlow-title{width: 100%; height: 100%}

.today-work-box{display: flex;width: 25%;height: 100px;background: #F9FBFC;margin-right: 5px;border-radius: 8px;flex-direction: column;justify-content: center;text-align: center;border: 2px solid #F4F6F6}
.today-work-box-contents{font-weight: 500;font-size: 18px}
.today-contents-box{overflow: auto;height: 100%; margin-top: 15px; white-space: nowrap}

.workFlow-contents-gray{font-size: 14px;font-weight: 500;color: #959BA0; margin-right: 5px}

.div-flex{display: flex; align-items: center;width: 90%;justify-content: center;}

.box-title{width: 100%;display: flex;align-items: center;justify-content: space-between;font-size: 17px;font-weight: 400;line-height: 32px;}
.department-drawer-area{display: flex;flex-direction: row;padding: 15px;height: 100%;}
.department-drawer-box{width: 100%;height: 100%;margin-right: 15px;display: flex;flex-direction: row;position: relative;padding: 20px;min-width: 0;word-wrap: break-word;background-color: #fff;background-clip: border-box;border-radius: 8px;min-height: 1px;margin-bottom: 20px;box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);}
.department-drawer-contents-area{display: flex;flex-direction: column;width: 50%;height: 100%;overflow: auto;}
.department-drawer-contents-box{padding: 10px;display: flex;flex-direction: column;width: 100%;height: 250px;}
.department-drawer-contents-select{width: 60%;}
.department-drawer-select-area{display: flex;justify-content: space-between;align-items: center;margin-top: 10px;}
.expedites-title {font-size: 15px;font-weight: 500;line-height: 15px;align-items: center;display: flex;}
.expedite-select{width: 80%}
.box-title-button{display: flex;align-items: center;justify-content: center;border-radius: 5px;background: #FF6533;color: #EEF2F5;}





.history-modal-p {
    font-size: 18px;
    font-weight: 500;
}
.history-modal-contents-area{

    display: flex;
    flex-direction: column;
    height: 245px;
    padding: 20px;
}
.history-modal-contents-column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}
.history-modal-contents-button-div {
    display: flex;
    justify-content: space-between;
    width: 240px;
}

.history-modal-contents-button {
    background: #FF6533;
    color: #FFFFFF;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 34px;
    padding: 2px 4px;
    font-weight: 500;
}
.history-modal-contents-ghost-button {
    background: #FFFFFF;
    color: #FF6533;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 34px;
    padding: 2px 4px;
    font-weight: 500;
}


.history-modal-contents-break-button {
    background: #FF6533;
    color: #FFFFFF;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    padding: 2px 4px;
    font-weight: 500;
    height: 50px;
    white-space: break-spaces;
}
.history-modal-contents-break-ghost-button {
    background: #FFFFFF;
    color: #FF6533;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 50px;
    white-space: break-spaces;
    padding: 2px 4px;
    font-weight: 500;
}


.history-modal-input {
    width: 105px;
    z-index: 10;
    text-align: center;
}

.history-modal-alert-p {
    font-size: 12px;
    color: #FF6533;
    font-weight: 400;
    margin-left: 10px;
}


.history-modal-button-area {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
}


.history-modal-input-middle {
    background: #FFFFFF;
    width: 30px;
    text-align: center;

    &:disabled {
        background-color: #FFFFFF;
        color: #FFF;
    }
}


.history-drawer-extra-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.history-drawer {

    .ant-drawer-header {
        padding: 50px 20px 20px 20px;
    }
}

.history-date-picker {

    width: 100px;
    height: 40px;
    .ant-picker-suffix {
        display: none;
    }
}



.history-modal {
    width: 450px !important;

    .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: #FFFFFF;
        background: #FF6533;
    }
    .ant-modal-body {
        height: 290px;
        padding: 32px 32px 12px;
    }
    .ant-modal-content {
        height: 400px;
        border-radius: 18px;
    }
    .ant-modal-footer {
        border-top: 0px;
        text-align: center;
    }
    .ant-modal-title {
        font-weight: 500;
        font-size: 20px;
    }
    .ant-modal-header {
        border-bottom: 0px;
        border-radius: 12px;
        padding: 30px;
    }
}

.history-modal-p {
    font-size: 15px;
    font-weight: 500;
}
.history-modal-sub-p {
    font-size: 15px;
}

.history-modal-contents-area{

    display: flex;
    flex-direction: column;
    height: 245px;
    padding: 20px;
}
.history-modal-contents-column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}
.history-modal-contents-button-div {
    display: flex;
    justify-content: space-between;
    width: 240px;
}

.history-modal-contents-button {
    background: #FF6533;
    color: #FFFFFF;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 34px;
    padding: 2px 4px;
    font-weight: 500;
}
.history-modal-contents-ghost-button {
    background: #FFFFFF;
    color: #FF6533;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 34px;
    padding: 2px 4px;
    font-weight: 500;
}


.history-modal-contents-break-button {
    background: #FF6533;
    color: #FFFFFF;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    padding: 2px 4px;
    font-weight: 500;
    height: 50px;
    white-space: break-spaces;
}
.history-modal-contents-break-ghost-button {
    background: #FFFFFF;
    color: #FF6533;
    border-radius: 11px;
    border: 2px solid;
    width: 72px;
    height: 50px;
    white-space: break-spaces;
    padding: 2px 4px;
    font-weight: 500;
}


.history-modal-input {
    width: 105px;
    z-index: 10;
    text-align: center;
}

.history-modal-alert-p {
    font-size: 12px;
    color: #FF6533;
    font-weight: 400;
    margin-left: 10px;
}


.history-modal-button-area {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
}


.history-modal-input-middle {
    background: #FFFFFF;
    width: 30px;
    text-align: center;

    &:disabled {
        background-color: #FFFFFF;
        color: #FFF;
    }
}



.table-area{display: flex; width: 100%; height: 950px; justify-content: space-between}
.user-table-box{width: 30%;display: flex; min-width: 280px; overflow: auto}
.dayOff-table-box{width: 63%;display: flex; min-width: 982px; overflow: auto}


.dayOff-table-p{
    font-weight: 400;
}

.dayOff-table-small-p{
    font-weight: 400;
    font-size: 12px;
    color:#959BA0;
    margin-top: 10px;
}
.ant-table-wrapper{width: 100%;}


.resignation-menu-selected{font-size: 14px;line-height: 46px;border: 0;box-shadow: none;text-align: center;font-weight: 500; color: #FF6533; white-space: nowrap; display: flex; align-items: center; justify-content: center; margin-left: 5px; margin-right: 5px}
.resignation-menu-none-selected{font-size: 14px;line-height: 46px;border: 0;box-shadow: none;text-align: center;font-weight: 500; color:#959BA0; white-space: nowrap; display: flex; align-items: center; justify-content: center; margin-left: 5px; margin-right: 5px}

.sub-switch-area{display: flex; align-items: center; justify-content: space-between; width: 150px}



.history-table-area {
    overflow: auto;
    width: 100%;
    height: 35%;
    margin-bottom: 15px;
}

.history-table-second-area {
    overflow: auto;
    width: 100%;
    height: 100%;
}


.history-table {
    height: 94%;
    width: 100%;
    background: #F9FBFC;

}


.table-primary-div {

    border-radius: 5px;
    background: #FF6533;
    color: #FFFFFF;
    border-color: #FF6533;
    margin-left: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-content: center;
    border: 2px solid #FF6533;
    height: 28px;
    padding: 5px;
    margin-right: 5px;

}


.salary-premium-table-box{overflow: auto;width: 100%;height: 700px;display: flex;}
.salary-premium-table-flow-area {width: 100%;display: flex;align-items: center;justify-content: space-between;line-height: 32px;margin-bottom: 20px; background: #F9FBFC}


.salary-premium-table-cell{display: flex;gap: 5px;align-items: center; color: #959BA0; font-weight: 500}

.workFlow-title-area{height: 105px;display: flex;justify-content: center;flex-direction: column;text-align: center;}
.workFlow-title-p{font-size: 25px;font-weight: 500;}
.workflow-box2{display: flex;flex-direction: column;align-items: center;margin-bottom: 10px;height: auto;gap: 10px;}
.workFlow-box3{width: 100%;min-height: 140px;padding: 19px;align-items: flex-start;border-radius: 8px;border: 1px solid #F4F6F6;background: #F9FBFC;margin-bottom: 10px;height: auto;}

.salary-table-item{z-index: 10;box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.10);height: 100%;width: 50%;display: flex;flex-direction: column;}




.salary-second-level-table {
    height: 94%;
    width: 100%;
    background: #F9FBFC;


    .ant-table-thead > tr > th {
        background: #F9FBFC;
    }

    .ant-table-tbody > tr > td {
        background-color: #F9FBFC;
    }

    .ant-table-thead {
        height: 75px;
    }

    .ant-table-tbody {
        height: 59px;
    }
}



.back-button {border-radius: 11px;width: 130px; height: 40px;font-size: 14px;font-weight: 400;background: #A1A1A1;color: #FFF;}


.black-button {
    background: #181818;
    color: #EEF2F5;
    margin-right: 10px;
    border-radius: 11px;
    min-width: 95px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
}


.work-drawer-title-area{display: flex;justify-content: space-between;align-items: center;font-size: 22px;font-weight: 500;}
.wage-p {
    font-size: 16px;
    font-weight: 500;
}

.not-item-area{height: 100%;display: flex;align-items: center;justify-content: center}
.not-item-text{font-size: 14px;font-weight: 500;color: #999}